import React, { useEffect } from 'react';
import Parse from 'parse';
import API from '@api';
import config from '@config';
import { withContext } from '@context';

const { APP_ID, JS_KEY, SERVER_URL } = config;
const collectionEntries = Parse.Object.extend('Entry');

const WebSockets = ({ currentCategory, user, categories, dispatch, history }) => {
  let client;
  let subscription;

  // useEffect(() => {
  //   if (user && user.sessionToken) {
  //     // client = new Parse.LiveQueryClient({
  //     //   applicationId: APP_ID,
  //     //   javascriptKey: JS_KEY,
  //     //   serverURL: SERVER_URL.replace(/(http)(s)?\:\/\//, 'ws$2://'),
  //     // });

  //     // client.open();
  //     init();
  //   }
  // }, [currentCategory]);


  useEffect(() => history.listen(() => {
    init();
    getActiveCagtegory();
  }), []);

  const getActiveCagtegory = () => {
    if (categories.length === 0) {
      return null;
    }

    let currentPath;
    const { pathname } = history.location;
    const path = pathname.split('/').filter(f => f !== "");

    if (pathname.startsWith('/documents') && path.length >= 1) {
      currentPath = path[1];
    }

    let activeCagtegory;
    if (currentPath) {
      activeCagtegory = categories.find(f => f.path === `/${currentPath}`);
    }

    if (activeCagtegory) {
      dispatch({
        type: 'CURRENT_CATEGORY_SET',
        payload: activeCagtegory,
      });
    }
  }

  const init = async () => {
    const { location } = history;
    const { pathname } = location;
    const currentPath = pathname.replace("/documents", "");
    if (!pathname.includes('/documents')) {
      return null;
    }

    if (currentPath === "" && currentCategory.path) {
      history.push(`/documents${currentCategory.path}`)
    }

    return null;

    if (subscription) {
      subscription.unsubscribe();
    }

    const query = new Parse.Query(collectionEntries);
    query.equalTo('path', currentPath);

    subscription = client.subscribe(query, user.sessionToken);

    subscription.on('enter', (newObj, oldObj) =>
      updateDb({ event: 'enter', newObj, oldObj }),
    );
    subscription.on('create', (newObj, oldObj) =>
      updateDb({ event: 'create', newObj, oldObj }),
    );
    subscription.on('update', (newObj, oldObj) =>
      updateDb({ event: 'update', newObj, oldObj }),
    );
    subscription.on('delete', (newObj, oldObj) =>
      updateDb({ event: 'delete', newObj, oldObj }),
    );
    subscription.on('leave', (newObj, oldObj) =>
      updateDb({ event: 'leave', newObj, oldObj }),
    );
  };

  const updateDb = async ({ event, newObj }) => {
    // console.log('event', event)
    // if (['update', 'create', 'enter'].includes(event)) {
    //   const newData = newObj.toJSON();
    //   const { shouldUpdateBin } = newData;


    //   try {
    //     const deviceInfoParseSubClass = await newObj.get('deviceInfo').fetch();
    //     const deviceInfo = deviceInfoParseSubClass.toJSON()
    //     const payload = {
    //       ...newData,
    //       deviceInfo,
    //     };

    //     await dispatch({
    //       type: 'DEVICE_INFO_SET',
    //       payload,
    //     });

    //     if (shouldUpdateBin) {
    //       try {
    //         const r = await fetch(`http://0.0.0.0:8080/api/update`).then(response => response.json());
    //         // const r = await fetch(`http://0.0.0.0:8080/update?fileUrl=http://localhost:8090/${appName}&version=${version}`).then(response => response.json());
    //         console.log('@@@', r)
    //       } catch (e) {
    //         console.log('ERROR', e.message);
    //       }
    //     }
    //   } catch (e) {
    //     console.log('ERROR', e.message)
    //   }

    //   await dispatch({
    //     type: 'DEVICE_ASSETS_SET',
    //     payload: newData.assets,
    //   });

    //   await dispatch({
    //     type: 'SNACKBAR_ADD',
    //     payload: event,
    //   });
    // }

    // if (['leave', 'delete'].includes(event)) {
    //   dispatch({
    //     type: 'SNACKBAR_ADD',
    //     payload: event,
    //   });
    // }
  };

  return null;
};

export default withContext(["user", "currentCategory", "categories"])(WebSockets);
