const initialState = [];

const setCategories = (state, payload) => {
  return payload;
};

const effects = {
  CATEGORIES_SET: setCategories,
};

export default {
  initialState,
  effects,
};
