import React from 'react';
import { withModal } from '@components/Modal';
import File from './File';
import Folder from './Folder';

// import { Toggle, Content, ToggleHolder } from './styles';
// <ToggleHolder>
//   <Toggle.Container>
//     <Toggle.Option selected={selectedFile} onClick={() => handleType(FILE)}>Файл</Toggle.Option>
//     <Toggle.Option selected={selectedFolder} onClick={() => handleType(FOLDER)}>Папка</Toggle.Option>
//   </Toggle.Container>
// </ToggleHolder>

const CreateNew = ({ closeFn, type }) => {
  return (
    <>
      {type === "file" && <File closeFn={closeFn} />}
      {type === "folder" && <Folder closeFn={closeFn} />}
    </>
  );
}


export default withModal(CreateNew)({});
