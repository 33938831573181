import styled, { createGlobalStyle, keyframes, css } from 'styled-components';
import { rgba } from 'polished';

export const GlobalSnackbarStyle = createGlobalStyle`
  .snackbar {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
  }
`;

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  border-radius: 4px;
  background-color: #343538;
  transition: transform 0.2s;

  ${({ isVisible }) => css`
    transform: translate(-50%, ${isVisible ? '-20px' : '100%'});
  `}
`;

export const Content = styled.div`
  padding: 8px 14px;
  color: ${rgba('#fff', 0.8)};
  font-size: 14px;
  font-weight: 300;

  b,
  strong {
    font-weight: 400;
  }
`;

const fadeIn = keyframes`
  0 {
    /* opacity: 0; */
    transform: translate(-50%, 100%);
  }
  100% {
    /* opacity: 1; */
    transform: translate(-50%, -20px);
  }
`;

const fadeOut = keyframes`
  0 {
    /* opacity: 1; */
    transform: translate(-50%, -20px);
  }
  100% {
    /* opacity: 0; */
    transform: translate(-50%, 100%);
  }
`;
