import React, { memo } from 'react';
import _ from 'lodash';
import initials from 'initials';

import { Container, Holder } from './styles';

const defaultColors = [
  '#2ecc71', // emerald
  '#3498db', // peter river
  '#8e44ad', // wisteria
  '#e67e22', // carrot
  '#e74c3c', // alizarin
  '#1abc9c', // turquoise
  '#2c3e50', // midnight blue
];

const sumChars = (str) => {
  let sum = 0;
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i);
  }

  return sum;
}

const Avatar = ({
  borderRadius = '100%',
  src,
  name,
  color,
  colors = defaultColors,
  size,
  style,
  onClick,
  className,
}) => {
  if (!name) {
    return null;
  };

  const innerStyle = {
    fontSize: `${size / 3}px`,
    borderRadius
  };

  if (size) {
    innerStyle.width = innerStyle.maxWidth = `${size}px`;
    innerStyle.height = innerStyle.maxHeight = `${size}px`;
  }

  let inner;
  if (src) {
    const imageStyle = {
      display: 'block',
      borderRadius,
      background: `url(${src}) no-repeat 50% / cover`,
    };

    inner = <div className="img" style={imageStyle} />
  } else {
    let background;
    if (color) {
      background = color;
    } else {
      // pick a deterministic color from the list
      let i = sumChars(name) % colors.length;
      background = colors[i];
    }

    innerStyle.backgroundColor = background;

    inner = initials(name);
  }

  if (innerStyle.backgroundColor) {
    // classes.push(styles[contrast(innerStyle.backgroundColor)]);
  }

  return (
    <Container aria-label={name} className={className} style={style}>
      <Holder style={innerStyle}>
        {inner}
      </Holder>
    </Container>
  )
}

const areEqual = (prevProps, nextProps) => _.isEqual(prevProps, nextProps);
export default memo(Avatar, areEqual);
