import styled, { keyframes, css } from 'styled-components';

export const TabsEl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TabEl = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: rgba(255,255,255, 1);
  font-weight: 400;
  background: rgba(0,0,0,.05);
  background: rgba(255, 255, 255, .1);
  transition: all .2s;

  ${({ isActive }) => !isActive && css`
    color: rgba(255,255,255, .6);
    font-size: 14px;
    /* font-weight: 300; */
  `}

  ${({ isActive }) => isActive && css`
    background: linear-gradient(to top,rgba(255,255,255, .1), transparent);
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0%;
      background-color: rgba(0,0,0, .1);
      pointer-events: none;
    }
  `}

  ${({ isActive, index }) => (isActive && index === 1) && css`
    background: rgba(255,255,255,.1);
    &:before {
      border-radius: 0 10px 0 0;
    }
  `}

  ${({ isActive, index }) => (isActive && index === 2) && css`
    background: rgba(255,255,255,.1);
    &:before {
      border-radius: 10px 0 0 0;
    }
  `}
`;

export const PaneEl = styled.div`
  overflow: hidden;
  overflow-y: auto;
  background: linear-gradient(to bottom,rgba(0,0,0,.1),transparent), linear-gradient(to bottom,rgba(255,255,255,.1),transparent);
  height: calc(100% - 160px);

  ${({ isActive, index }) => (isActive && index === 1) && css`
    height: calc(100% - 60px);
  `}
`;
