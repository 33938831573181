import React from 'react';
import Dropzone from 'react-dropzone';
import { useSetState } from "@utils/hooks";
import { getFileExtOnly, prepareFileName } from '@utils/helpers';
import Icon from '@components/Icon';
import { DropzoneElement, FileIcon } from './styles';

const FileUpload = ({ onDrop, multiple = false, usePlusIcon, color }) => {
  const [state, setState] = useSetState({
    file: null
  });

  const onDropFn = (accepted, rejected) => {
    if (rejected.length > 0) {
      console.log('Може да се ъплоудва само 1 файл', rejected);
    } else if (accepted.length > 0) {
      const files = accepted.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
          params: {
            name: prepareFileName(file.name),
            extension: getFileExtOnly(file.name),
          }
        })
      });

      setState(state => {
        state.files = multiple ? files : files[0];
      });

      onDrop(multiple ? files : files[0]);
    }
  }

  let content;

  if (usePlusIcon) {
    content = (
      <Icon name="icon-plus" size={[20]} fill={color} />
    )
  } else {
    if (state.file) {
      content = (
        <FileIcon>
          <Icon name="icon-file" size={[100]} />
          {state.file.params.extension && <span>{`.${state.file.params.extension}`}</span>}
        </FileIcon>
      )
    } else {
      content = (
        <FileIcon>
          <Icon name="icon-file-dashed" size={[100]} />
          <Icon name="icon-upload-dashed" fill="#dadada" size={[40]} />
        </FileIcon>
      )
    }
  }

  return (
    <Dropzone accept=".jpg, .jpeg, .png, .pdf, .doc, .csv, .docx, .doc" onDrop={onDropFn} multiple={multiple}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <DropzoneElement
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {/* <div>
            {isDragReject && "Пусни файла тук :)"}
            {!isDragActive && "Прикачи файл"}
          </div> */}

          {content}
        </DropzoneElement>
      )}
    </Dropzone>
  );
}

export default FileUpload;
