import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: #F3F8FF;

  * {
    user-select: none;
  }
`;

export const List = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  height: 60px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  color: #273142;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;

  > span {
    display: block;
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover {
    color: #538FFF;
    transition: color .1s linear
  }

  ${({ isCurrent }) => isCurrent && `
    color: #333;
  `}

  &:before,
  &:after {
    color: #A4B9D2;
    font-size: 20px;
    line-height: 0px;
  }

  &:after {
    content: '›';
    padding: 0 14px;
  }

  &:last-of-type {
    &:after {
      content: '';
    }
  }

  &:first-child,
  &:nth-last-child(1),
  &:nth-last-child(2),
  &:nth-last-child(3),
  &:nth-last-child(4),
  &:nth-last-child(5),
  &:nth-last-child(6) {
    display: inline-flex;
    width: auto;

    &:before {
      content: '';
    }
  }

  &:nth-last-child(n+3):nth-child(6) {
    display: inline-flex;
    overflow: hidden;
    width: auto;

    font-size: 0;
  }

  display: none;

  &:before {
    content: '...';
  }
`

export const GoUpButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-right: 14px;
  border-radius: 50%;
  background-color: #538FFF;
  cursor: pointer;

  > svg {
    margin-left: -2px;
  }

  &:hover {
    background-color: #5889E5;
  }

  ${({ hidden }) => hidden && `
    opacity: .3;
    pointer-events: none;
  `}
`;
