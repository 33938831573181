import React from 'react';
import { Route, Switch } from 'react-router-dom';
import page from './page';

export default {
  sidebar: [],
  routes: [
    <Route
      key='routes'
      render={({ match: { url } }) => {
        return (
          <Switch>
            <Route path={`/`} component={page} />
          </Switch>
        )
      }}
    />
  ],
};
