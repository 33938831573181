import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const UserMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  color: #fff;
  border-bottom: 1px solid #303842;
  background-color: #323c48;
`;

export const LeftPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Name = styled.div`
  margin-left: 14px;
  font-size: 14px;
  font-weight: 300;
  padding: 0 10px;
`;

export const Container = styled.div`
  min-width: 200px;

  button, a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 6px;
    height: 40px;
    color: inherit;
    font-size: 14px;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #333c48;
    }
  }
`;
