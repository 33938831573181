let initialState = {};

const userSet = (_, payload) => {
  return payload;
};

const userReset = (_, payload) => {
  return {};
};

const userUpdate = (state, payload) => {
  return { ...state, ...payload };
};

const effects = {
  USER_SET: userSet,
  USER_RESET: userReset,
  USER_UPDATE: userUpdate,
};

export default {
  initialState,
  effects
};
