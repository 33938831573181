import React from 'react';
import { Route, Switch } from 'react-router-dom';
import sidebar from './sidebar';
import page from './page';
import grid from './grid';

export default {
  sidebar: [
    <Route
      key='sidebar'
      render={({ match: { url } }) => {
        return (
          <Switch>
            <Route path={`/:type(documents)`} component={sidebar} />
          </Switch>
        )
      }}
    />
  ],

  routes: [
    <Route
      key='routes'
      render={({ match: { url } }) => {
        return (
          <Switch>
            <Route exact path={`/:type(documents)`} component={page} />
            <Route path={`/:type(documents)/:companyPath`} component={grid} />
          </Switch>
        )
      }}
    />
  ],
};
