import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  color: white;
`;

export const Holder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  letter-spacing: 1px;

  .img {
    width: 100%;
    height: 100%;
  }

  .light {
    color: gray;
  }

  .light & {
    border: 1px solid lightgray;
  }
`;
