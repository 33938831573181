import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  *:focus,
  textarea:focus,
  input:focus,
  button:focus {
    outline: none;
  }

  html, body {
    padding: 0;
    margin: 0;
    color: #333;
    height: 100%;
    background: #fff;
    font-family: "Ubuntu", sans-serif;
    -webkit-text-size-adjust: none;
    overflow: hidden;

    --color-0: #2c3e50;
    --color-1: #e74c3c;
    --color-2: #f39c12;
    --color-3: #9b59b6;
    --color-4: #3498db;
    --color-5: #2ecc71;
    --color-6: #33485c;
  }


  #svgSprite {
    display: none;
    pointer-events: none;
  }

  #root {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow: hidden;
  }

  #root-extras {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }

  .react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;


    &.react-contextmenu--visible {
      opacity: 1;
      pointer-events: auto;
      z-index: 9999;
    }

    .react-contextmenu-item {
      background: 0 0;
      border: 0;
      color: #373a3c;
      cursor: pointer;
      font-weight: 400;
      line-height: 1.5;
      padding: 3px 20px;
      text-align: inherit;
      white-space: nowrap;

      &.react-contextmenu-item--active,
      &.react-contextmenu-item--selected {
        color: #fff;
        background-color: #20a0ff;
        border-color: #20a0ff;
        text-decoration: none;
      }

      &.react-contextmenu-item--disabled,
      &.react-contextmenu-item--disabled:hover {
        background-color: transparent;
        border-color: rgba(0, 0, 0, .15);
        color: #878a8c;
      }

      &.react-contextmenu-submenu {
        padding: 0;
      }

      &.react-contextmenu-submenu > .react-contextmenu-item {}

      &.react-contextmenu-submenu>.react-contextmenu-item:after {
        content: "▶";
        display: inline-block;
        position: absolute;
        right: 7px;
      }
    }

    .react-contextmenu-item--divider {
      border-bottom: 1px solid rgba(0, 0, 0, .15);
      cursor: inherit;
      margin-bottom: 3px;
      padding: 2px 0;

      &:hover {
        background-color: transparent;
        border-color: rgba(0, 0, 0, .15);
      }
    }

      .example-multiple-targets::after {
      content: attr(data-count);
      display: block;
    }
  }

  .flex-container {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;

    display: flex;
    justify-content: center;
    align-content: stretch;
    align-items: flex-start;
    background-color: rgba(0,0,0, .8);
    z-index: 9999;
  }

  .dialog {
    order: 0;
    flex: 0 1 auto;
    align-self: center;
    border-radius: 10px;
    width: 250px;
    background: rgba(220, 220, 220, 0.85);
  }

  .dialog-header {
    text-align: center;
    padding: 10px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    border-bottom: rgb(180,180,180) solid 1px;
  }

  .dialog-content {
    padding: 10px 12px;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 13px;
    border-bottom: rgb(180,180,180) solid 1px;
  }

  .dialog-buttons {
    > span, > a {
      display:block;
      text-align: center;
      text-decoration: none;
      padding: 10px;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #2e7cf1;
      border-bottom: rgb(180,180,180) solid 1px;

      &:last-child {
        border-bottom: none;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &:active {
        background: rgb(180,180,180);
      }

      p {
        line-height: 18px;
      }
    }
  }


`;
