import React, { useState, memo } from 'react';
import { withPopOver } from '@components/PopOver';
import styled, { keyframes, css } from 'styled-components';
import { triangle } from 'polished';
import useOutsideClick from "@utils/hooks/useOutsideClick";

const getPositionStyle = (elem, target, offset = 10, pH = 0, pV = -10) => {
  const props = {
    style: {},
    position: {},
    arrow: [],
  };

  const bElem = elem.getBoundingClientRect();
  // const eWidth = Math.ceil(bElem.width);
  // const eHeight = Math.ceil(bElem.height);
  const eTop = Math.ceil(bElem.top);
  const eRight = Math.ceil(bElem.right);
  const eBottom = Math.ceil(bElem.bottom);
  const eLeft = Math.ceil(bElem.left);

  const bTarget = target.getBoundingClientRect();
  const tWidth = Math.ceil(bTarget.width);
  const tHeight = Math.ceil(bTarget.height);
  // const tTop = Math.ceil(bTarget.top);
  // const tRight = Math.ceil(bTarget.right);
  // const tBottom = Math.ceil(bTarget.bottom);
  // const tLeft = Math.ceil(bTarget.left);


  // TOP
  let topPos;
  if (eBottom + tHeight + offset >= document.body.offsetHeight - offset) {
    props.position.top = true;
    props.arrow[0] = 'bottom';
    topPos = eTop - tHeight - offset;
  } else {
    props.position.bottom = true;
    props.arrow[0] = 'top';
    topPos = eBottom + offset - pV;
  }

  let leftPos;
  if (eLeft + tWidth + offset >= document.body.offsetWidth - offset) {
    props.position.left = true;
    props.arrow[1] = 'right';
    leftPos = eRight - tWidth + offset - pH;
  } else {
    props.position.right = true;
    props.arrow[1] = 'left';
    leftPos = eLeft - pH;
  }

  props.style.top = topPos;
  props.style.left = leftPos;

  return props;
};

const Menu = ({ children, refButton, closeFn }) => {
  let refContainer = React.useRef();

  const [state, handleState] = useState(null);

  if (!state) {
    setTimeout(() => {
      handleState(getPositionStyle(refButton.current, refContainer.current));
    }, 0);
  }

  useOutsideClick(refContainer, () => {
    closeFn();
  });

  return (
    <Container ref={refContainer} state={state}>
      <div>
        {children}
      </div>
    </Container>
  );
}

export default withPopOver(memo(Menu))({});

const Container = styled.div`
  position: absolute;
  color: #7f8fa4;
  font-size: 16px;
  text-align: left;
  border-radius: 4px;
  border: 1px solid #e7e9ed;
  background-color: #fff;
  box-shadow: 0 0 0 4px rgba(0,0,0, .03);
  opacity: 0;
  transition: 0.3s ease;
  z-index: 1;

  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  ${({ state }) => state && css`
    top: ${`${state.style.top}px`};
    left: ${`${state.style.left}px`};
    animation: ${fadeIn} .4s forwards;

    &:before {
      ${triangle({ pointingDirection: state.arrow[0], width: '27px', height: '13px', foregroundColor: 'rgba(0,0,0, .03)' })}
    }
    &:after {
      ${triangle({ pointingDirection: state.arrow[0], width: '23px', height: '11px', foregroundColor: '#fff' })}
    }
  `}

  ${({ state }) => state && `
    ${state.position.top ? `
      &:before {
        bottom: -18px;
      }
      &:after {
        bottom: -10px;
        filter: drop-shadow(0px 1px 0 #e7e9ed);
      }
    `: ''}

    ${state.position.right ? `
      &:before {
        left: 10px;
      }
      &:after {
        left: 12px;
      }
    `: ''}

    ${state.position.bottom ? css`
      &:before {
        top: -18px;
      }
      &:after {
        top: -10px;
        filter: drop-shadow(0px -1px 0 #e7e9ed);
      }
    `: ''}
    ${state.position.left ? css`
      &:before {
        right: 10px;
      }
      &:after {
        right: 12px;
      }
    `: ''}
  `}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
