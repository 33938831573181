import Parse from 'parse';
import storage from 'local-storage-fallback';
import request from '@utils/request';
import config from '@config';

const { APP_ID, JS_KEY, BASE_URL } = config;

const apiInstance = request.create({ baseURL: `${BASE_URL}api` });

const parseInstance = request.create({ baseURL: `${BASE_URL}1` });
parseInstance.defaults.headers.common['X-Parse-Application-Id'] = APP_ID;
parseInstance.defaults.headers.common['X-Parse-JavaScript-Key'] = JS_KEY;

apiInstance.interceptors.request.use((c) => {
  let originalRequest = c;
  const token = JSON.parse(storage.getItem('docs-token'));
  if (token) {
    originalRequest.headers['docs-token'] = token;
  }

  return originalRequest;
}, (err) => {
  return Promise.reject(err);
});

const API = {
  login: async ({ username, password }) => {
    return apiInstance.post('/login', {
      username,
      password,
    }).then(({ data }) => data);
  },

  logout: async () => {
    return apiInstance.post('/logout').then(({ data }) => data);
  },

  me: async () => {
    return apiInstance.get('/me').then(({ data }) => data);
  },

  changePassword: async (password) => {
    return apiInstance.post('/changePassword', { password }).then(({ data }) => data);
  },

  companies: {
    list: async params => {
      return apiInstance
        .get(`/companies`, {
          params,
        })
        .then(({ data }) => {
          return data;
        });
    },
  },

  file: {
    upload: async params => {
      const { name, extension, base64 } = params;
      const fileForUpload = new Parse.File(`${name}.${extension}`, { base64 });
      try {
        const data = await fileForUpload.save();
        return data.toJSON();
      } catch (e) {
        return e.message;
      }
    }
  },

  entries: {
    search: async params => {
      return apiInstance
        .get(`/entries/search`, {
          params,
        })
        .then(({ data }) => {
          return data;
        });
    },
    getHomeWork: async params => {
      return apiInstance
        .get(`/homework-entries`, {
          params,
        })
        .then(({ data }) => {
          return data;
        });
    },
    getHomeWorkDone: async params => {
      return apiInstance
        .get(`/homework-entries-done`, {
          params,
        })
        .then(({ data }) => {
          return data;
        });
    },
    list: async params => {
      return apiInstance
        .get(`/entries`, {
          params,
        })
        .then(({ data }) => {
          return data;
        });
    },
    createFolderIfNotExist: async params => {
      return apiInstance
        .post(`/createFolderIfNotExist`, params)
        .then(({ data }) => {
          return data;
        });
    },
    create: async params => {
      return apiInstance
        .post(`/entries`, params)
        .then(({ data }) => {
          return data;
        });
    },
    delete: async objectId => {
      return apiInstance
        .delete(`/entries/${objectId}`)
        .then(({ data }) => {
          return data;
        });
    },
    rename: async params => {
      const { objectId, ...payload } = params;
      return apiInstance
        .patch(`/entries/${objectId}`, { payload })
        .then(({ data }) => {
          return data;
        });
    },
    getTotalSize: async params => {
      return apiInstance
        .get(`/entries/size`, {
          params,
        })
        .then(({ data }) => {
          return data;
        });
    },
    markAsPaid: async (objectId) => {
      return apiInstance
        .patch(`/entries/${objectId}`, { payload: { paid: true } })
        .then(({ data }) => {
          return data;
        });
    },
    setFirstPreview: async params => {
      const { objectId, ...payload } = params;
      return apiInstance
        .patch(`/entries/${objectId}`, { payload })
        .then(({ data }) => {
          return data;
        });
    },
  },

  getDevice: async params => {
    return apiInstance
      .get(`/device`, {
        params,
      })
      .then(({ data }) => {
        return data;
      });
  },

  updateDeviceAssets: async params => {
    return apiInstance
      .patch('/device', {
        params,
      })
      .then(({ data }) => {
        return data;
      });
  },
};

export default API;
