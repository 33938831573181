import React, { useRef, useEffect, useLayoutEffect } from "react";
import _ from 'lodash';
import { withContext } from '@context';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import API from '@api';
import { useSetState } from '@utils/hooks';
import { getPathName, cancellablePromise } from '@utils/helpers';
import ShowWhenAdmin from '@utils/showWhenAdmin';
import SearchBar from '@components/SearchBar';
import Breadcrumbs from '@components/Breadcrumbs';
import ItemInfo from '@components/ItemInfo';
import Grid from './grid';
import Item from './item';

import { Container } from './styles';

const Component = ({ currentCategory, entries, dispatch, history, location, match }) => {
  const refContainer = useRef();

  const [state, setState] = useSetState({
    selectedIndex: 0,
    showItemInfo: false,
    columnCount: 0,
  });

  useEffect(() => history.listen(async () => {
    const { location } = history;
    const { pathname } = location;
    if (pathname !== '/documents' && pathname !== '/') {
      check();
    }
  }), []);

  useEffect(() => {
    check();
  }, [currentCategory]);

  const check = async () => {
    await dispatch({
      type: 'ENTRIES_RESET',
      payload: {},
    });

    await setState(state => {
      state.selectedIndex = 0;
      state.showItemInfo = false;
    });

    const { companyPath } = match.params;
    const { path } = currentCategory;

    if (companyPath === path.substr(1)) {
      loadItems(true);
    }
  }


  useLayoutEffect(() => {
    const { offsetWidth } = refContainer.current;

    const gridWidth = offsetWidth - 40;
    const columnCount = Math.floor((gridWidth) / 140);

    setState(state => {
      state.columnCount = columnCount;
    });

    refContainer.current.focus();
  }, [refContainer]);


  pendingPromises
  let pendingPromises = [];

  const appendPendingPromise = promise => {
    pendingPromises = [...pendingPromises, promise];
  }

  const removePendingPromise = promise => {
    pendingPromises = pendingPromises.filter(p => p !== promise);
  }

  const loadItems = async (reset) => {
    const { items, total = null } = entries;

    const { createdAt } = items.slice(-1)[0] || {};
    let afterDate = createdAt || null;

    if (reset) {
      afterDate = null;
    }

    const wrappedPromise = cancellablePromise(
      API.entries.list({ afterDate, limit: 100, path: getPathName() })
    );

    appendPendingPromise(wrappedPromise);

    try {
      const data = await wrappedPromise.promise;
      removePendingPromise(wrappedPromise);

      const { results, count } = data;

      let payload = {
        items: reset ? results : _.uniqBy([...entries.items, ...results], 'objectId'),
      }

      payload.total = Number.isInteger(count) ? count : total;

      await dispatch({
        type: 'ENTRIES_SET',
        payload,
      });
    } catch (e) {
      removePendingPromise(wrappedPromise);
    }
  }

  const cancelRenameHandler = () => {
    setState(state => {
      state.renameIndex = null;
    });

    highlightSelected(state.selectedIndex);
  }

  const highlightSelected = (index) => {
    const grid = refContainer.current;
    if (grid) {
      const selectedElements = grid.getElementsByClassName('selected') || [];
      [...selectedElements].forEach(el => {
        el.classList.remove('selected');
      });

      const currentEl = document.getElementById(`item-${index}`);
      if (currentEl) {
        currentEl.classList.add('selected');
      }

      setState(state => {
        state.selectedIndex = index;
      });
    }
  }

  const handleGoUp = async () => {
    const { pathname } = location;
    const goUpLink = pathname.substring(0, pathname.lastIndexOf('/'));

    if (goUpLink !== '/documents') {

      history.push(goUpLink);
    }
  }

  const handleFolderOpen = async (goToFolderPath) => {
    const { selectedIndex } = state;

    const { path, name, type } = entries.items[selectedIndex];

    if (type === 'folder') {
      const goTo = goToFolderPath || `/documents${path}/${name}`;

      history.push(goTo);
    }
  }

  const updateItem = ({ objectId, ...rest }) => {
    const itemIndex = entries.items.findIndex(f => f.objectId === objectId);
    const updatedItem = entries.items[itemIndex];

    Object.keys(rest).forEach(key => {
      updatedItem[key] = rest[key];
    });

    entries.items[itemIndex] = updatedItem;

    dispatch({
      type: 'ENTRIES_UPDATE',
      payload: entries,
    });
  }

  const handleDelete = async (objectId) => {
    try {
      const data = await API.entries.delete(objectId);
      dispatch({
        type: 'ENTRIES_DELETE',
        payload: objectId,
      });
    } catch (e) {
      dispatch({
        type: 'SNACKBAR_ADD',
        payload: 'Възникна грешка!',
      });
    }
  }

  const handleKeys = async (key, e) => {
    e.preventDefault();

    const { total } = entries;

    const {
      selectedIndex,
      columnCount,
    } = state;

    if (key === 'esc') {
      if (state.showItemInfo) {
        toggleItemInfo();
      }

      return null;
    }

    if (key === 'space') {
      toggleItemInfo();
      return null;
    }

    if (state.showItemInfo) {
      return null;
    }


    if (key === 'enter') {
      // setState(state => {
      //   state.renameIndex = selectedIndex;
      // });

      // highlightSelected(selectedIndex);

      return null;
    }


    if (key === 'ctrl+up' || key === 'cmd+up') {
      handleGoUp();
      return null;
    }

    if (key === 'ctrl+down' || key === 'cmd+down') {
      handleFolderOpen();
      return null;
    }

    let currentEl = document.getElementById(`item-${selectedIndex}`);
    if (!currentEl) {
      return null;
    }

    let newIndex = selectedIndex;

    switch (key) {
      case 'up':
        newIndex = - (columnCount);
        break;
      case 'right':
        newIndex = +1;
        break;
      case 'down':
        newIndex = + (columnCount);
        break;
      case 'left':
        newIndex = -1;
        break;
      default:
        e.preventDefault()
    }

    newIndex = selectedIndex + (newIndex);

    if (newIndex < 1) {
      newIndex = 0
    } else if (newIndex > total - 1) {
      newIndex = selectedIndex;
    } else if (newIndex >= total - 1) {
      newIndex = total - 1
    }

    if (newIndex === selectedIndex || newIndex > total) {
      return null;
    }

    highlightSelected(newIndex);

    setTimeout(() => {
      currentEl.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 100);
  }

  const toggleItemInfo = () => {
    const { selectedIndex } = state;

    const { type } = entries.items[selectedIndex];

    if (type === 'file') {
      setState(state => {
        state.showItemInfo = !state.showItemInfo;
      });
    }

  }

  const renderItems = () => {
    let content

    if (entries.items.length > 0) {
      content = entries.items.map((item, i) => {
        return (
          <Item
            {...item}
            key={item.objectId}
            index={i}
            showRename={state.renameIndex === i}
            toggleItemInfo={toggleItemInfo}
            highlightSelected={highlightSelected}
            updateItem={updateItem}
            cancelRenameHandler={cancelRenameHandler}
            handleFolderOpen={handleFolderOpen}
            handleDelete={handleDelete}
          />
        )
      });
    }

    return content;
  }

  return (
    <>
      <SearchBar />
      <Breadcrumbs prefix="/documents" showCreateBtn={true} />
      <Container ref={refContainer}>
        <Grid
          dataLength={entries.total}
          selectedIndex={state.selectedIndex}
          loadItems={loadItems}
          hasMore={entries.items.length < entries.total}
          highlightSelected={highlightSelected}
        >
          {renderItems()}
        </Grid>
      </Container>
      <KeyboardEventHandler
        handleFocusableElements={false}
        handleKeys={['esc', 'enter', 'up', 'right', 'down', 'left', 'space', 'ctrl+down', 'cmd+down', 'ctrl+up', 'cmd+up']}
        onKeyEvent={(key, e) => handleKeys(key, e)}
      />

      {
        state.showItemInfo && (
          <ItemInfo
            entry={entries.items[state.selectedIndex]}
            cancelRenameHandler={cancelRenameHandler}
            updateItem={updateItem}
            closeFn={toggleItemInfo}
          />
        )
      }
    </>
  )
}

export default withContext(['currentCategory', 'entries'])(Component);
