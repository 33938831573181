import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withContext } from '@context';
import { useSetState } from '@utils/hooks';
import { isAdmin } from '@utils/showWhenAdmin';
import API from '@api';
import { sort } from "@utils/helpers";
import Snackbar from '@components/Snackbar';
import AdminLayout from '@components/AdminLayout';
import UserLayout from '@components/UserLayout';
import Notfound from '@pages/default';

import { isBrowser, isMobile } from "react-device-detect";

const Component = ({
  user,
  token,
  dispatch,
  history,
  location,
}) => {

  const [state, setState] = useSetState({
    loading: true,
  });

  useEffect(() => {
    init();
  }, [token]);

  const getActiveCagtegory = (categories) => {
    if (categories.length === 0) {
      return null;
    }

    let currentPath;
    const { pathname } = history.location;
    const path = pathname.split('/').filter(f => f !== "");

    if (pathname.startsWith('/documents') && path.length >= 1) {
      currentPath = path[1];
    }

    let activeCagtegory;
    if (currentPath) {
      activeCagtegory = categories.find(f => f.path === `/${currentPath}`);
    } else {
      activeCagtegory = categories[0];
    }

    dispatch({
      type: 'CURRENT_CATEGORY_SET',
      payload: activeCagtegory,
    });
  }

  const init = async () => {
    const me = async () => {
      try {
        const payload = await API.me();
        return payload;
      } catch (e) {
        dispatch({
          type: 'SNACKBAR_ADD',
          payload: e.message,
        });
      }
    };

    const loadCategories = async () => {
      try {
        const data = await API.companies.list();
        const payload = sort(data.results, 'name');

        getActiveCagtegory(payload);

        return payload;
      } catch (e) {
        dispatch({
          type: 'SNACKBAR_ADD',
          payload: e.message,
        });
      }
    }

    if (token) {
      try {
        const [user, categories] = await Promise.all([me(), loadCategories()]);

        user.isAdmin = isAdmin({ user, categories, location });

        dispatch({
          type: 'USER_SET',
          payload: user,
        });

        if (categories) {
          dispatch({
            type: 'CATEGORIES_SET',
            payload: categories,
          });
        }


      } catch (e) {
        dispatch({
          type: 'USER_SET',
          payload: {},
        });

        dispatch({
          type: 'TOKEN_DELETE',
          payload: {},
        });
      }
    }

    setState(state => {
      state.loading = false;
    });
  }


  if (state.loading) {
    return null;
  }

  return (
    <>
      {
        <Switch>

          {
            (token && user.isAdmin && isBrowser) && (
              <Switch>
                <Redirect exact from="/" to='/documents' />
                <Route
                  path={"/documents"}
                  render={() => {
                    return (
                      <Route render={(props) => {
                        return <AdminLayout {...props} user={user} />
                      }} />
                    )
                  }}
                />
              </Switch>
            )
          }

          <Route path="/" render={(props) => {
            return <UserLayout {...props} user={user} />
          }} />
          <Route path="*" component={Notfound} />
        </Switch>
      }
      <Snackbar />
    </>
  );
};

export default withContext(['token', 'user', 'categories'])(Component);
