import React from "react";
import { Tabs, useTabState, usePanelState } from "@bumaga/tabs";

import {
  TabsEl,
  TabEl,
  PaneEl
} from './styles';

const Tab = ({ index, children }) => {
  const data = useTabState();
  const { isActive, onClick } = data;

  return <TabEl isActive={isActive} index={index} onClick={onClick}>{children}</TabEl>;
};

const Panel = ({ index, children }) => {
  const isActive = usePanelState();

  return isActive ? <PaneEl isActive={isActive} index={index}>{children}</PaneEl> : null;
};

export default ({ contentOne, contentTwo }) => (
  <Tabs>
    <TabsEl>
      <Tab index={1}>Домашна Работа</Tab>
      <Tab index={2}>Моите Документи</Tab>
    </TabsEl>

    <Panel index={1}>{contentOne}</Panel>
    <Panel index={2}>{contentTwo}</Panel>
  </Tabs>
);
