import FileSaver from 'file-saver';
import moment from 'moment';
import 'moment/locale/bg';

export const generateUID = (len = 16) => {
  return `${'x'.repeat(len)}`.replace(/[x]/g, () => {
    return (Math.random() * 16 | 0).toString(16);
  }).toLowerCase();
}

export const ShowWhenAdmin = ({ isAdmin, children }) => {
  return isAdmin
    ? children
    : null
}

export const getPathName = (prefix = "/documents") => {
  const path = decodeURI(location.pathname.replace(prefix, ''));
  return path;
}

export const cancellablePromise = promise => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      value => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      error => reject({ isCanceled, error }),
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => (isCanceled = true),
  };
};

export const toLocalTime = (d, format) => {
  if (!d) return null;

  const date = moment(d);
  const local = moment(date).local().locale('BG').format(format || 'MM-DD-YYYY HH:mm');
  return local;
}

const compare = k => (a, b) => {
  if (a[k] < b[k] || !a[k] || !b[k]) {
    return -1;
  }

  if (a[k] > b[k]) {
    return 1;
  }

  return 0;
};

export const sort = (arr, key) => {
  return [...arr].sort(compare(key));
};


export const getFileNameOnly = (fileName) => fileName.replace(/\.[^\.]+$/, '');
export const getFileExtOnly = (fileName) => fileName.substr(fileName.lastIndexOf('.') + 1);


export const prepareFileName = (fileName) => {
  const name = getFileNameOnly(fileName);
  const newFileName = name.replace(/[^A-Z0-9]+/ig, "_").replace(/\s+/g, "_").replace(/\_$/, '')

  return newFileName;
}


// DOWNLOAD
export const getFullFileNameFromURL = (url) => url.substring(url.lastIndexOf('/') + 1);

export const getRealFileNameFromURL = (url) => {
  const fileName = getFullFileNameFromURL(url);
  return fileName.substring(fileName.indexOf('_') + 1)
}

export const saveFileOnUserDevice = async (url) => {
  const realFileName = getRealFileNameFromURL(url);
  FileSaver.saveAs(url, realFileName);

  return null;
}

export const convertText = (string) => {
  var urls = string.match(/(((ftp|https?):\/\/)[\-\w@:%_\+.~#?,&\/\/=]+)/g);
  if (urls) {
    urls.forEach((url) => {
      if (url.match(/youtu/)) {
        string = string.replace(url, `<a class="youtu" target="_blank" href="${url}" />виж тук</a>`);
      }
      else {
        string = string.replace(url, '<a target="_blank" href="' + url + '">' + url + "</a>");
      }
    });
  }
  return string.replace(/(?:\r\n|\r|\n)/g, '<br />');
}
