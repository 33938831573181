import styled, { keyframes, css } from 'styled-components';

export const IconContainer = styled.div`
  position: relative;
  width: 140px;
  height: 140px;
  padding: 10px;
  display: flex;
  justify-content: center;
  user-select: none;

  .react-contextmenu-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  font-weight: lighter;
  font-size: 14px;
  color: #535b62;
  padding: 20px 10px 10px;
  border-radius: 8px;
  border: 1px solid transparent;
  transition: all .1s;
  cursor: pointer;

  &.selected {
    border: 1px solid #dbebff;
    background-color: #f0f7ff;
  }

  ${({ selected, showRename }) => (selected && !showRename) && css`
    border: 1px solid #dbebff;
    background-color: #f0f7ff;

    ${Name} {
      /* color: #fff; */
      /* background-color: #0e7eff; */
    }
  `}

  ${({ flickRename }) => flickRename && css`
    opacity: .4;
    pointer-events: none;
  `}

  ${({ isNew }) => isNew && css`
    opacity: 0;
    animation: ${fadeIn} .4s .2s forwards;
  `}
`;

export const Logo = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 64px;
  width: 100%;
  max-height: 64px;
  height: 100%;
  margin-bottom: 4px;

  & span {
    position: absolute;
    bottom: 8px;
    left: 16px;
    right: 16px;
    font-weight: 400;
    color: white;
    font-size: 11px;
    text-overflow: ellipsis;
    text-shadow: 0 0 2px rgba(0,0,0, .05);
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const PaidIcon = styled.i`
  position: absolute;
  top: 0;
  left: -14px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #4EC431;
`;

export const PreviewFrom = styled.i`
  position: absolute;
  top: 24px;
  left: -14px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #4EC431;
`;

export const Img = styled.img`
  display: block;
  width: 44px;
`;

export const Name = styled.div`
  max-width: 100%;
  word-wrap: break-word;
  text-align: center;
  border: 2px solid transparent;
  border-radius: 3px;
  padding: 0 4px;
`;

export const TextareaRename = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  border: 2px solid #6db3fd;
  min-height: 18px;
  /* box-shadow: 3px 3px 0 #6db3fd, -3px -3px 0 #6db3fd, -3px 3px 0 #6db3fd, 3px -3px 0 #6db3fd; */

  textarea {
    font-size: 12px;
    padding: 0 24px;
    min-height: 18px;
    text-align: center;
    color: #535b62;
    font-size: 14px;
    font-weight: 300;
    border: none;
    border-radius: 4px;
    border-color: #6db3fd;
    background: none;
    resize: none;
    width: calc(100% + 40px);
    margin: 0 -20px;
  }
`;

const fadeIn = keyframes`
  0% { opacity: 0;transform: scale(.8)}
  100% { opacity: 1;transform: scale(1)}
`;
