import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Parse from 'parse';
import Isvg from 'react-inlinesvg';
import ReactResizeDetector from 'react-resize-detector';
import { AppProvider } from '@context';
import App from './app';
import { GlobalStyle } from '@assets/styles';
import { CalendarGlobalStyle } from '@assets/styles/calendar';
import svgSprite from '@assets/img/sprite.svg';

import config from '@config';
const { APP_ID, JS_KEY, SERVER_URL } = config;
Parse.initialize(APP_ID, JS_KEY);
Parse.serverURL = SERVER_URL;

const Entry = () => {
  const onResize = () => {
    setTimeout(() => {
      location.reload()
    }, 100);
  }

  return (
    <>
      <GlobalStyle />
      <CalendarGlobalStyle />
      <AppProvider>
        <Router>
          <ReactResizeDetector skipOnMount handleWidth handleHeight onResize={onResize}>
            <App />
          </ReactResizeDetector>
        </Router>
      </AppProvider>

      <div id="svgSprite">
        <Isvg src={svgSprite} uniquifyIDs={false} />
      </div>
    </>
  );
};

const rootElement = document.getElementById('root');

const render = Component => {
  ReactDOM.render(<Component />, rootElement);
};

render(Entry);
