import React from 'react';
import { withContext } from '@context';


const Component = ({ user = {}, children }) => {
  const { roles = {} } = user;
  const { admin = [] } = roles;
  if (admin[0] === "*") {
    return children;
  }

  return null;
}

export default withContext(['user'])(Component);
