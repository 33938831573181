import { useEffect, useState } from 'react';

const useOutsideClick = (ref, callback) => {
  const [select, setSelect] = useState(false);

  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      if (!select) {
        callback();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    document.addEventListener('onselect', () => setSelect(true));

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('onselect', handleClick);
    };
  });
};

export default useOutsideClick;
