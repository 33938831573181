const initialState = {};

const setCurrentCategory = (state, payload) => {
  return payload;
};

const effects = {
  CURRENT_CATEGORY_SET: setCurrentCategory,
};

export default {
  initialState,
  effects,
};
