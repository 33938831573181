import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding: 20px;
  height: calc(100% - 130px);
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
`;
