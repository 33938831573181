import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  margin: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  text-decoration: none;

  svg {
    margin-right: 10px;
  }
`;
