import React, { Component, createRef } from 'react';
import _ from 'lodash';
import { useSetState, useInput } from "@utils/hooks";
import API from '@api';
import Icon from '@components/Icon';
import SearchResults from './SearchResults';

import { TopBar, SearchHolder, SearchResultsContainer } from './styles';

const SearchBar = () => {
  const [state, setState] = useSetState({
    term: '',
    data: []
  });

  const renderInput = () => {
    const [field, fieldInput] = useInput({
      component: "input",
      type: "text",
      placeholder: "търси",
      value: state.term || "",
      onChange: onTyping,
    });

    return fieldInput;
  }

  const onTyping = async (value) => {
    setState(state => {
      state.term = value.trim();
    });

    if (value && value !== "" && value.length >= 3) {
      try {
        const data = await API.entries.search({ term: value });
        setState(state => {
          state.data = data;
        });
      } catch (e) {
        console.error(e.message);
      }
    }
  };

  return (
    <>
      <TopBar>
        <SearchHolder>
          <Icon name="icon-search" size={[20, 20]} fill="#535b62" />
          {renderInput()}
        </SearchHolder>
      </TopBar>
      {
        state.term.length > 0 && (
          <SearchResultsContainer>
            <SearchResults
              data={state.data}
              term={state.term}
              prefix="/documents"
              closeResult={() => setState(state => state.term = '')}
            />
          </SearchResultsContainer>
        )
      }
    </>
  );
}

export default SearchBar;
