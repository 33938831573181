import { withRouter } from 'react-router-dom';
import { connect } from './simpply';
import { createProvider, createSystemStorage } from './simpply';

import token from './token';
import user from './user';
import theme from './theme';
import categories from './categories';
import currentCategory from './currentCategory';
import entries from './entries';
import snackbar from './snackbar';

const systemStorage = createSystemStorage({
  token,
  user,
  theme,
  categories,
  currentCategory,
  entries,
  snackbar,
});

const AppProvider = createProvider(systemStorage, {
  logging: false,
});

const withContext = props => Comp => {
  const mapStateToProps = state => {
    const obj = {};
    props.forEach(p => {
      obj[p] = state[p];
    });

    return obj;
  };

  return connect(mapStateToProps)(withRouter(Comp));
};

export { AppProvider, withContext };
