import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Title = styled.div`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 10px;
`;

export const Description = styled.div`
  color: #606081;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 40px;

  strong {
    color: #555572;
    font-weight: 400;
  }
`;

// .empty {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0

//   > div {
//     max-width: 900px;
//     margin: 0 auto;
//     text-align: center;
//   }

//   &.parentScroll {
//     position: fixed;
//     top: 0;
//     left: 300px;
//     pointer-events: none;

//     > div {
//       pointer-events: none;
//     }

//     a {
//       pointer-events: all;
//     }
//   }
// }

// .title {
//   min-width: 400px;
//   font-size: 40px;
//   font-weight: 300;
//   margin-bottom: 10px;
//   // white-space: nowrap;

//   &.smallTitle {
//     font-size: 34px;
//   }
// }

// .description {
//   color: lighten(#555572, 5%);
//   font-weight: 300;
//   font-size: 16px;
//   line-height: 22px;
//   margin-bottom: 40px;

//   strong {
//     color: #555572;
//     font-weight: 400;
//   }
// }

// .icon {
//   width: 170px;
//   height: 170px;
//   margin: 0 auto 24px auto;
//   border-radius: 100%;
//   background: #f3f0f2;
//   text-align: center;
//   padding-top: 45px;
// }
