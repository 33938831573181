import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const FilterHolder = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  padding: 0 14px;
  border-left: 3px solid #00a9ff;
  background-color: #373f49;
`;

export const Filter = styled.input`
  margin: 0;
  padding: 0;
  color: #fff;
  width: 100%;
  padding: 0 64px 0 16px;
  height: 40px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  background: #2b333e;

  transition: all 0.30s ease-in-out;

  &:focus {
    box-shadow: 0 0 5px rgba(0, 169, 255, 1);
    border: 1px solid rgba(0, 169, 255, 1);
  }

  ${({ searchTextLength }) => searchTextLength === 0 && `
    & + div {
      display: none;
    }
  `}

  ${({ companiesLength }) => companiesLength === 0 && `
    padding-right: 110px;
  `}
`;

export const Results = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 0 4px 4px 0;
  font-size: 12px;
  color: rgba(255,255,255, .6);
  padding-right: 24px;
  white-space: nowrap;
`;

export const ScrollableContainer = styled.div`
  position: relative;
  overflow-y: auto;
  /* width: 330px; */
  height: 100%;
`;


export const Container = styled.aside`
  position: relative;
  margin-bottom: 16px;
  padding-left: 20px;
  transition: all .2s ease-in-out;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  padding-right: 10px;
  padding-left: 13px;
  color: rgba(255,255,255, .6);
  font-size: 16px;
  font-weight: 300;

  > svg {
    margin-right: 10px;
  }
`;

export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  position: relative;
  height: 22px;
  padding-left: 0;
  padding-right: 10px;
  transition: all .2s ease-in-out;
  text-decoration: none;
  outline: none;

  &.active,
  &:hover {
    .name {
      color: #fff;
    }

    > span {
      color: #fff !important;
    }
  }

  span {
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: rgba(255,255,255, .6);
    font-size: 13px;
    font-weight: 300;

    &.name {
      padding-top: 4px;
      padding-left: 6px;
      color: rgba(255,255,255, .6);
      font-size: 13px;
      font-weight: 300;
    }

    &.count {
      margin-left: auto;
      max-width: 40px;
      color: rgba(255,255,255,.4);
      text-align: right;
    }
  }
`;
