import styled, { keyframes, css } from 'styled-components';
import youtubeIcon from '@assets/img/youtube.png';
const rHeight = 80;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #223140;
  overflow: hidden;

  h4 {
    position: relative;
    font-size: 12px;
    font-weight: 400;
    margin: 0 20px 40px;
    border-bottom: 1px dotted rgba(255,255,255, .2);
    border-bottom: 1px dashed rgba(255,255,255,.4);
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .addBtnHolder {
    position: absolute;
    left: 50%;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to top,rgba(255,255,255,.1),transparent);

    .addBtn {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #fff;

      > div {
        width: 48px;
        height: 48px;
      }
    }
  }
`;

export const Loader = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: #0092ff;
  transform: translate(-50%, -50%);
  overflow: hidden;
  transition: opacity .4s;
  pointer-events: none;
  opacity: 0;
  z-index: 9999;

  ${({ isLoading }) => isLoading && css`
    opacity: 1;
  `}
`;

export const Btn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 54px;
  height: ${rHeight}px;
  cursor: pointer;
  z-index: 9999;

  &:active {
    opacity: 0.8;
  }
`;

export const Section = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  transition: transform 0.3s;


  ${({ isCurrent, dayIndex }) => (!isCurrent && dayIndex !== 0 && dayIndex !== 6) && css`
    /* &:active {
      opacity: .9;
    } */
  `}

  ${({ sectionIndex, dayIndex }) => sectionIndex === 1 && `
    background: var(--color-${dayIndex});
    transform: translateY(${ rHeight * sectionIndex}px);
    transition - delay: 0s;
    z-index: ${ 1000 * sectionIndex};
  `}

  ${({ sectionIndex, dayIndex }) => sectionIndex === 2 && `
    background: var(--color-${dayIndex});
    transform: translateY(${ rHeight * sectionIndex}px);
    transition-delay: 0.05s;
    z-index: ${ 1000 * sectionIndex};
  `}

  ${({ sectionIndex, dayIndex }) => sectionIndex === 3 && `
    background: var(--color-${dayIndex});
    transform: translateY(${ rHeight * sectionIndex}px);
    transition-delay: 0.1s;
    z-index: ${ 1000 * sectionIndex};
  `}

  ${({ sectionIndex, dayIndex }) => sectionIndex === 4 && `
    background: var(--color-${dayIndex});
    transform: translateY(${ rHeight * sectionIndex}px);
    transition-delay: 0.15s;
    z-index: ${ 1000 * sectionIndex};
  `}

  ${({ sectionIndex, dayIndex }) => sectionIndex === 5 && `
    background: var(--color-${dayIndex});
    transform: translateY(${ rHeight * sectionIndex}px);
    transition-delay: 0.20s;
    z-index: ${ 1000 * sectionIndex};
  `}

  ${({ sectionIndex, dayIndex }) => sectionIndex === 6 && `
    background: var(--color-${dayIndex});
    transform: translateY(${ rHeight * sectionIndex}px);
    transition-delay: 0.20s;
    z-index: ${ 1000 * sectionIndex};
  `}

  ${({ sectionIndex, dayIndex }) => sectionIndex === 7 && `
    background: var(--color-${dayIndex});
    transform: translateY(${ rHeight * sectionIndex}px);
    transition-delay: 0.20s;
    z-index: ${ 1000 * sectionIndex};
  `}

  ${({ isCurrent }) => isCurrent && css`
    transform: translateY(0);
  `}

  ${({ isNext }) => isNext && css`
    transform: translateY(100%);
  `}

  ${({ isCurrent }) => isCurrent && css`
    > h1 {
      background: rgba(255,255,255, .1);
      transition: all .2s .4s;
    }
    > div {
      animation: ${isCurrent ? fadeIn : fadeOut} .4s ${isCurrent ? '.2s' : '0s'} forwards;
    }
  `}
`;

export const Title = styled.h1`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
  height: ${rHeight}px;
  text-transform: capitalize;
  user-select: none;
  text-shadow: 0 0 2px rgba(0,0,0, .05);
  transition: all 0 0;
`;

export const TimeToPlay = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 14px;
  bottom: 0;
  overflow: hidden;
`;

export const Calendar = {
  Holder: styled.span`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 14px;
    bottom: 0;
    text-align: right;
    overflow: hidden;
  `,
  Month: styled.span`
    position: absolute;
    top: 50%;
    right: 0;
    width: 100%;
    margin-top: -24px;
    font-size: 10px;
    opacity: .8;
    font-weight: 500;
    text-transform: uppercase;
  `,
  Day: styled.span`
    width: 100%;
    opacity: .8;
    font-size: 26px;
    font-weight: 300;
  `,
};

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    color: #fff;
    font-weight: 300;
    margin: 0 0 30px;
  }
`;

export const Settings = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: ${rHeight}px;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  overflow: hidden;
  color: #fff;
  opacity: 0;
  pointer-events: none;

  ${({ isSectionOpen }) => isSectionOpen && css`
    opacity: 1;
    pointer-events: all;
  `}

  .version {
    position: absolute;
    right: 0;
    bottom: 30px;
    left: 0;
    font-size: 9px;
    text-align: center;
    pointer-events: none;
  }
`;

export const Content = styled.div`
  text-shadow: 0 0 2px rgba(0,0,0, .05);
  color: #fff;
  font-size: 16px;
  height: calc(100% - ${rHeight}px);
  overflow: hidden;
  opacity: 0;

  p {
    margin-top: 0;
    font-size: 16px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 30px 14px;
  align-items: center;
  justify-items: center;

  margin-bottom: 60px;
`;

export const FileIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100px;
  height: 100px;

  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & span {
    position: absolute;
    bottom: 8px;
    left: 20px;
    right: 20px;
    font-weight: 400;
    color: white;
    font-size: 14px;
    text-overflow: ellipsis;
    text-shadow: 0 0 2px rgba(0,0,0, .05);
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const PaidIcon = styled.i`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #4EC431;
  ${({ backgroundColor }) => backgroundColor && `
    background-color: ${backgroundColor};
  `}
`;

export const Item = {
  Holder: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 14px;
      margin: 14px 0 0;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100px;
    }
  `,
  Number: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 60px;
    font-size: 20px;
    font-weight: 700;
  `,
  Left: styled.div`
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Right: styled.div`
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Icon: styled.a`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100px;
    width: 100%;
    max-height: 100px;
    height: 100%;

    & span {
      position: absolute;
      bottom: 0;
      left: 20px;
      right: 16px;
      height: 20px;
      font-weight: 500;
      color: white;
      font-size: 12px;
      letter-spacing: 1px;
      text-overflow: ellipsis;
      text-shadow: 0 0 2px rgba(0,0,0, .05);
      white-space: nowrap;
      overflow: hidden;

      ${({ color }) => color && `
        color: ${color};
      `}
    }
  `,
  Info: styled.div`
    flex: 1;
    padding: 20px 10px;
    height: 100%;
  `,
};


export const Desc = styled.div`
  padding: 20px 20px 40px;
  font-size: 14px;

  a {
    color: #fff;

    &.youtu {
      display: block;
      width: 60px;
      height: 60px;
      font-size: 0;
      background: url(${youtubeIcon}) no-repeat 50% 50% / 100%;
    }
  }
`;

export const TabsEl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0, .1);
`;

export const TabEl = styled.div`
  flex: 1;
`;


const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const fadeOut = keyframes`
  100% { opacity: 0; }
`;
