import home from './home';

const pages = [home];
const routes = pages.map((p) => p.routes).filter(p => p);
const sidebar = pages.map((p) => p.sidebar).filter(p => p);

export default {
  toolbar,
  sidebar,
  routes,
};
