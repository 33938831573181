import React from "react";
import _ from 'lodash';
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyState from "@components/EmptyState";

const Grid = ({
  selectedIndex,
  dataLength,
  loadItems,
  hasMore,
  highlightSelected,
  children,
}) => {
  return (
    <InfiniteScroll
      className="grid"
      dataLength={dataLength}
      next={loadItems}
      hasMore={hasMore}
      loader={<h4>...</h4>}
    >
      {children}
    </InfiniteScroll>
  )

  return (
    <>
      <GridLayout
        options={{
          isEqualSize: true,
          horizontal: false,
        }}
        layoutOptions={{
          margin: 0,
          // align: "justify",
          itemSize: 140,
        }}
        onLayoutComplete={onLayoutComplete}
        onAppend={loadItems}>
        {children}
      </GridLayout>
    </>
  )
}

export default Grid;
