import storage from 'local-storage-fallback';
const sData = storage.getItem('docs-token');
const token = sData !== "" && JSON.parse(sData);

let initialState = "";
if (token) {
  initialState = token;
}

const setToken = (_, payload) => {
  storage.setItem('docs-token', JSON.stringify(payload));
  return payload;
};

const deleteToken = (_, payload) => {
  storage.removeItem('docs-token');
  return null;
};

const effects = {
  TOKEN_SET: setToken,
  TOKEN_DELETE: deleteToken,
};

export default {
  initialState,
  effects
};
