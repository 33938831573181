import styled from 'styled-components';

export const SideBarContainer = styled.section`
  position: fixed;
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > a {
    text-decoration: none;
    display: flex;
  }

  + div {
    left: 300px;
  }
`;

export const MenuContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: #2b333e;
`;

export const ScrollableContainer = styled.div`
  position: relative;
  overflow-y: auto;
  width: 330px;
  height: 100%;
`;

export const Aside = styled.aside`
  position: relative;
  max-width: 300px;
`;
