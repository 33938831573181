import { useEffect, useState } from "react";

export default function useDocumentVisibility(action) {
  const docVisible = typeof window === 'undefined' ? null : document.visibilityState
  const [documentVisibility, setDocumentVisibility] = useState(docVisible)

  const handleVisibilityChange = () => {
    if (!docVisible) return

    if (action) {
      action(document.visibilityState);
    }

    setDocumentVisibility(document.visibilityState);
  }

  useEffect(() => {
    window.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    }
  }, [])

  return documentVisibility;
}
