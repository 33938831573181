import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 14px;
  border-bottom: 1px solid #303842;
  background-color: #333c48;

  color: #fff;
  font-size: 20px;
  font-weight: 300;
  text-decoration: none;
`;
