import { isMobile } from "react-device-detect";

const initialState = {
  isSidebarOpen: isMobile ? false : true,
}

const themeUpdate = (state, payload) => {
  return { ...state, ...payload };
}

const effects = {
  THEME_UPDATE: themeUpdate,
};

export default {
  initialState,
  effects
};
