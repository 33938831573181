import styled, { keyframes, css } from 'styled-components';
import { lighten } from 'polished';

export const Spinner = styled.div`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;

  ${({ center }) => center && `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    ${({ color }) => color && `
      border: 4px solid ${color};
    `}
    border-top-color: ${lighten(.2, '#66bbff')};
    border-radius: 100%;
    transform: translate(-50%, -50%);

    ${() => css`
      animation: ${loaderSpinner} .6s infinite linear;
    `}
    z-index: 2;
  }
`;

const showSpinner = keyframes`
  0% { opacity: 0;top: -80px;transform: translate(-50%, -50%); }
  100% { opacity: 1;top: 0;transform: translate(-50%, -50%); }
`;


const loaderSpinner = keyframes`
  0% { transform: translate(-50%, -50%) rotate(0); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
`;
