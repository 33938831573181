import React, { useEffect, useRef } from 'react';
import { withContext } from '@context';
import SearchBar from '@components/SearchBar';
import Breadcrumbs from '@components/Breadcrumbs';
import Icon from '@components/Icon';
import { Grid, StyledNavLink } from './styles';

import {
  IconContainer,
  Container,
  Logo,
  Name,
} from "../grid/item/styles";

const Page = ({ categories, history }) => {

  const refContainer = useRef();

  const highlightSelected = (index) => {
    const grid = refContainer.current;
    if (grid) {
      const selectedElements = grid.getElementsByClassName('selected') || [];
      [...selectedElements].forEach(el => {
        el.classList.remove('selected');
      });

      const currentEl = document.getElementById(`item-${index}`);
      if (currentEl) {
        currentEl.classList.add('selected');
      }
    }
  }

  const linksRender = categories.map(({ objectId, path, name, link, count }, i) => {
    return (
      <IconContainer
        key={objectId}
        onClick={() => highlightSelected(i)}
        onTouchEnd={() => history.push(`/documents${path}`)}
        onDoubleClick={() => history.push(`/documents${path}`)}
      >
        <Container id={`item-${i}`}>
          <Logo>
            <Icon name={`icon-folder`} size={[64]} />
          </Logo>
          <Name>{name}</Name>
        </Container>
      </IconContainer>
    );
  });

  return (
    <>
      <SearchBar />
      <Breadcrumbs prefix="/documents" isRoot={true} />
      <Grid ref={refContainer}>
        {linksRender}
      </Grid>
    </>
  );
};

export default withContext(['categories'])(Page);
