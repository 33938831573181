import React, { useEffect } from "react";
import moment from 'moment';
import { withContext } from "@context";
import API from "@api";
import { useSetState, useInput } from "@utils/hooks";
import { getPathName } from '@utils/helpers';
import { Modal } from "@components/Modal";
import Button from "@components/Button";
import FileUpload from '@components/FileUpload';

import { Container, Left, Right, Label } from './styles';

const Component = ({ closeFn, currentCategory, dispatch }) => {
  const [state, setState] = useSetState({
    data: {
      type: "file",
      file: null,
      // number: null,
      desc: null,
    },
    requiredData: ["type", "file"],
    autoFocus: true,
    btnIsDisabled: true,
    isLoading: false,
    validationErrorMessage: ""
  });

  const onDataChange = (key, value) => {
    const { data, requiredData } = state;

    data[key] = value;

    let btnIsDisabled = false;
    for (let k in requiredData) {
      if (!btnIsDisabled) {
        if (!data[requiredData[k]] || data[requiredData[k]] === "") {
          btnIsDisabled = true;
        }
      }
    }

    setState(state => {
      state.data.homeWorkDate =
        state.data[key] = value;
      state.btnIsDisabled = btnIsDisabled;
    });
  };



  const isValidString = (isRequired, name) => {
    const { data, requiredData } = state;
    let isValid = true;

    if (isRequired && requiredData.includes(name)) {
      isValid = !data[name] ? data[name] !== "" : true;
    }

    return isValid;
  };

  const renderInput = (isRequired, type, name, label, rows) => {
    const isValid = isValidString(isRequired, name);

    const [field, fieldInput] = useInput({
      label,
      type,
      rows,
      value: state.data[name] || "",
      isRequired,
      isValid,
      validationErrorMessage: "Задължително поле",
      onChange: (value) => onDataChange(name, value.trim()),
    });

    return fieldInput;
  };

  const onFileUpload = (file) => {
    onDataChange("file", file);
  };

  const onSave = async () => {
    setState(state => {
      state.isLoading = true;
    });

    const upload = new Promise((resolve, reject) => {
      const { file } = state.data;
      const {
        name,
        extension,
        size,
      } = file.params;
      const reader = new FileReader();

      reader.onloadend = async () => {
        try {
          const base64 = reader.result;
          const data = await API.file.upload({
            name,
            extension,
            base64,
          });

          const newEntry = {
            ...state.data,
            file: data,
            name,
            extension,
            size,
          };

          resolve(newEntry);
        } catch (e) {
          reject(e);
        }
      };

      reader.readAsDataURL(file);
    });


    try {
      const newEntry = await upload;

      const getHomeWorkDate = () => {
        const now = moment();
        const result =
          moment(getPathName().replace('/homework', ''), 'MM.DD.YYYY')
            .set({
              'hour': now.hour(),
              'minute': now.minute(),
              'second': now.second()
            })
            .toISOString();

        return result;
      }

      const payload = await API.entries.create({
        companyId: currentCategory.objectId,
        data: {
          ...newEntry,
          path: getPathName(),
          homeWorkDate: getHomeWorkDate()
        }
      });

      await dispatch({
        type: "ENTRIES_CREATE",
        payload,
      });

      closeFn();
    } catch (e) {
      console.log('@@@@', e);
      if (e.response.status === 409) {
        setState(state => {
          state.isLoading = false;
          state.btnIsDisabled = true;
          state.validationErrorMessage = `${state.name} вече съществува`;
        });
      } else {
        dispatch({
          type: "SNACKBAR_ADD",
          payload: "Възникна грешка!",
        });
      }

    }
  };

  return (
    <Modal.Container size="xs">
      <Modal.Header title="Избери файл" />
      <Modal.Content>
        <Container>
          <Left>
            <FileUpload name="file" onDrop={file => onFileUpload(file)} />
          </Left>
          <Right>
            {/* {renderInput(true, "text", "name", "име на документа")} */}
            {/* {renderInput(true, "number", "number", "номер на документа")} */}
            {/* {renderInput(false, "text", "desc", "описане на документа", 4)} */}
            {/* {renderInput(true, "number", "price", "сума")} */}
          </Right>
        </Container>
      </Modal.Content>
      <Modal.Footer>
        <Button
          onClick={closeFn}
          theme={{
            color: "grey",
            size: "md",
            borderRadius: "4px",
            minWidth: "100px",
          }}
        >Откажи</Button>
        <Button
          onClick={onSave}
          theme={{
            color: "blue",
            size: "md",
            borderRadius: "4px",
            minWidth: "100px",
            disabled: state.btnIsDisabled,
            isLoading: state.isLoading,
          }}
        >Добави</Button>
      </Modal.Footer>
    </Modal.Container>
  );
};

export default withContext(["currentCategory"])(Component);
