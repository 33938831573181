import React from 'react';
import Icon from '@components/Icon';
import config from '@config';
const { APP_NAME } = config;

import { StyledNavLink } from './styles';

const Logo = ({ style }) => {
  return (
    <StyledNavLink to="/" style={style}>
      <Icon name="icon-folder" fill="#77b5f1" size={[30]} />
      {APP_NAME}
    </StyledNavLink>
  );
};


export default Logo;
