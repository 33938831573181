import React, { useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/bg';
import config from '@config';
import { withContext } from '@context';
import { useSetState } from '@utils/hooks';
import API from '@api';
import { getRealFileNameFromURL, convertText } from '@utils/helpers';
import { useDocumentVisibility } from '@utils/hooks';
import FileUpload from '@components/FileUpload';
import Spinner from '@components/Spinner';
import Button from '@components/Button';
import Icon from '@components/Icon';

import Tabs from "./tabs";

const { APP_NAME } = config;

import PullRefresh from 'react-pullrefresh'

import Login from './login';

import {
  Wrapper,
  Btn,
  Section,
  Title,
  Calendar,
  Content,
  Grid,
  Item,
  FileIcon,
  Settings,
  UserInfo,
  PaidIcon,
  TimeToPlay,
  Loader,
  Desc,
} from './styles';


const Page = ({ user, categories, dispatch }) => {
  const [state, setState] = useSetState({
    loading: true,
    dialog: {
      show: false,
      item: null,
    },
    daysOfWeek: [],
    data: {
      type: "file",
      file: null,
    },
    requiredData: ["type", "file"],
    isSectionOpen: false,
    currentIndex: 0,
  });

  useDocumentVisibility((state) => {
    if (state === "visible") {
      load();
    }
  });


  useEffect(() => {
    init();
  }, []);


  const init = async () => {
    if (!user.sessionToken) {
      setState(state => {
        state.isSectionOpen = true;
        state.currentIndex = 0;
      });
    }

    await load();

    setTimeout(() => {
      setState(state => {
        state.loading = false;
      });
    }, 600);

  }


  const dialogActions = {
    show: (item) => {
      setState(state => {
        state.dialog = {
          show: true,
          item,
        }
      });
    },
    hide: () => {
      setState(state => {
        state.dialog = {
          show: false,
          item: null,
        }
      });
    },
    delete: async () => {
      try {
        const data = await API.entries.delete(state.dialog.item.objectId);

        await load();

        setState(state => {
          state.dialog = {
            show: false,
            item: null,
          }
        });

      } catch (e) {
        dispatch({
          type: 'SNACKBAR_ADD',
          payload: 'Възникна грешка!',
        });
      }
    }
  }

  const load = async () => {
    try {
      let results = [];
      if (user.sessionToken) {
        results = await API.entries.getHomeWorkDone();
      } else {
        results = await API.entries.getHomeWork();
      }

      const daysOfWeek = [...Array(7).keys()].map((__, i) => {
        const date = moment().subtract(i, 'd').startOf('day');
        const dayName = date.locale('BG').format("dddd");
        const month = date.locale('BG').format("MMMM");
        const day = date.locale('BG').format("DD");

        return {
          dayIndex: date.day(),
          dayName,
          month,
          day,
          folderName: `${date.format("MM.DD.YYYY")}`,
          homeWorkDate: date,
          items: [],
        }
      });

      results.forEach(item => {
        const day = moment(item.homeWorkDate.iso).day();
        const dayIndex = daysOfWeek.findIndex(f => f.dayIndex === day);
        const currentItems = daysOfWeek[dayIndex].items;

        if (item.type === 'file') {
          daysOfWeek[dayIndex].items = [...currentItems, item];
        }

        if (item.type === 'folder') {
          daysOfWeek[dayIndex].desc = item.desc;
        }
      });

      setState(state => {
        state.daysOfWeek = daysOfWeek.reverse();
      });
    } catch (e) {
      console.log('@@@ reload', e.message);
      toggleMenu({ open: true })
      // location.reload();
    }
  }

  const logOut = async () => {
    await dispatch({
      type: 'USER_SET',
      payload: {},
    });

    await dispatch({
      type: 'TOKEN_DELETE',
      payload: {},
    });

  }

  const toggleMenu = ({ open }) => {
    setState(state => {
      state.isSectionOpen = open !== undefined ? open : !state.isSectionOpen;
      state.currentIndex = 0;
    });
  }

  const goToPage = (page) => {
    setState(state => {
      state.isSectionOpen = true;
      state.currentIndex = page;
    });
  }

  const onFileUpload = async (allFiles, item) => {
    setState(state => {
      state.loading = true;
    });

    const file = allFiles[0];

    const currentCategory = categories.find(f => f.path !== '/homework');
    const filePath = `${currentCategory.path}/${item.folderName}`;

    const upload = (f, homeWorkDate) => new Promise((resolve, reject) => {
      const {
        name,
        extension,
      } = f.params;
      const reader = new FileReader();

      reader.onloadend = async () => {
        try {
          const base64 = reader.result;
          const data = await API.file.upload({
            name,
            extension,
            base64,
          });

          const newEntry = {
            type: "file",
            file: data,
            name,
            extension,
            size: file.size,
            homeWorkDate,
          };

          resolve(newEntry);
        } catch (e) {
          reject(e);
        }
      };

      reader.readAsDataURL(file);
    });

    await API.entries.createFolderIfNotExist({
      companyId: currentCategory.objectId,
      data: {
        path: filePath,
      },
    });

    const promises = allFiles.map((file) => {
      return upload(file, item.homeWorkDate);
    });

    try {
      const data = await Promise.all(promises);

      const p = data.map((newEntry) => {
        return API.entries.create({
          companyId: currentCategory.objectId,
          data: {
            ...newEntry,
            path: filePath,
          },
          skipDublicates: true,
        });
      })

      await Promise.all(p);
      await load();

      setState(state => {
        state.loading = false;
      });
    } catch (e) {
      setState(state => {
        state.loading = false;
      });

      if (e.response.status === 409) {
        dispatch({
          type: "SNACKBAR_ADD",
          payload: "Възникна грешка!",
        });
      } else {
        dispatch({
          type: "SNACKBAR_ADD",
          payload: "Възникна грешка!",
        });
      }
    }

  };

  const renderGridItems = (items, dayIndex) => {
    const color = `var(--color-${dayIndex})`;
    const homeWorkItems = items.filter(f => f.path.startsWith('/homework'));

    const result = homeWorkItems.map((entry, index) => {
      return (
        <Item.Holder key={entry.objectId}>
          <Item.Icon
            href={entry.file.url}
            target="_blank"
            download={getRealFileNameFromURL(entry.file.url)}
            color={color}
          >
            <Icon name="icon-file" size={[90]} />
            {entry.extension && <span>{`.${entry.extension}`}</span>}
          </Item.Icon>
          <h3>{entry.name}</h3>
        </Item.Holder>
      );
    });

    return result;
  }

  const renderMyItems = (items, dayIndex) => {
    const color = `var(--color-${dayIndex})`;
    const homeWorkItemsDone = items.filter(f => !f.path.startsWith('/homework'));

    const result = homeWorkItemsDone.map((entry, index) => {
      return (
        <Item.Holder key={entry.objectId}>
          <Item.Icon
            onClick={() => dialogActions.show(entry)}
            color={color}
          >
            <Icon name="icon-file" size={[90]} />
            {entry.extension && <span>{`.${entry.extension}`}</span>}
          </Item.Icon>
          <h3>{entry.name}</h3>
        </Item.Holder>
      );
    });

    return result;
  }

  const renderSections = () => {
    const content = state.daysOfWeek.map((item, i) => {
      const color = `var(--color-${item.dayIndex})`;
      const isCurrent = state.isSectionOpen && state.currentIndex === i + 1;
      const isTimeToPlay = item.dayIndex === 0 || item.dayIndex === 6;


      return (
        <Section
          key={item.dayName}
          dayIndex={item.dayIndex}
          sectionIndex={i + 1}
          isCurrent={isCurrent}
          isNext={state.isSectionOpen && state.currentIndex < i + 1}
          onClick={() => (!isCurrent && !isTimeToPlay) && goToPage(i + 1)}
        >
          <Title data-shadow-text={item.dayName}>
            {
              (isTimeToPlay) && (
                <TimeToPlay>
                  <Icon name="icon-play-time" size={[40]} />
                </TimeToPlay>
              )
            }
            {item.dayName}
            <Calendar.Holder>
              <Calendar.Month>{item.month}</Calendar.Month>
              <Calendar.Day>{item.day}</Calendar.Day>
            </Calendar.Holder>
          </Title>

          <Content>
            <Tabs
              contentOne={
                <>
                  {
                    item.desc && (
                      <>
                        <h4 style={{ marginBottom: 0, paddingTop: 20 }}>Описание</h4>
                        <Desc dangerouslySetInnerHTML={{ __html: convertText(item.desc) }} />

                      </>
                    )
                  }
                  {
                    (item.items.length > 0)
                      ? (
                        <>
                          <h4>Документи от преподавател</h4>
                          <Grid>
                            {renderGridItems(item.items, item.dayIndex)}
                          </Grid>
                        </>
                      )
                      : (
                        <p style={{ padding: 20 }}>Все още домашните не са побликувани!</p>
                      )
                  }
                </>
              }

              contentTwo={
                <>
                  <h4 style={{ marginBottom: 30, paddingTop: 20 }}>
                    за {item.day} {item.month}
                  </h4>
                  <div className="addBtnHolder">
                    <div className="addBtn">
                      {
                        user.sessionToken
                          ? (

                            <FileUpload name="file" multiple usePlusIcon color={color} onDrop={file => onFileUpload(file, item)} />
                          )
                          : (
                            <Btn onClick={toggleMenu}>
                              <Icon name="icon-lock" size={[24]} fill="#fff" />
                            </Btn>
                          )

                      }
                    </div>
                  </div>
                  <Grid>
                    {renderMyItems(item.items, item.dayIndex)}
                  </Grid>
                </>
              }
            />
          </Content>
        </Section>
      )
    });

    return content;
  }

  return (
    <>
      <Wrapper>
        <PullRefresh
          onRefresh={load}
          zIndex={9999}
        >
          <Title>{APP_NAME}</Title>

          <Settings isSectionOpen={state.isSectionOpen}>
            {
              user.sessionToken
                ?
                <UserInfo>
                  <h3>{user.email}</h3>
                  <Button
                    onClick={logOut}
                    theme={{
                      color: 'blue',
                      size: 'lg',
                      borderRadius: '4px',
                    }}
                  >Изход</Button>
                </UserInfo>
                : <Login toggleMenu={toggleMenu} />
            }
            <span className="version">v1.0.0</span>
          </Settings>

          {
            state.isSectionOpen
              ? (
                <Btn onClick={toggleMenu}>
                  <Icon name="icon-menu" size={[24]} fill="#fff" />
                </Btn>
              )
              : (
                <Btn onClick={toggleMenu}>
                  <Icon name="icon-lock" size={[24]} fill="#fff" />
                </Btn>
              )
          }

          {renderSections()}

          {
            state.dialog.show && (
              <div className="flex-container">
                <div className="dialog">
                  <div className="dialog-content">
                    <p>Изберете действие</p>
                  </div>
                  <div className="dialog-buttons">
                    <span style={{ color: '#ec4e3f' }} onClick={() => dialogActions.delete()}>Изтриване на файл</span>
                    <a
                      onClick={() => dialogActions.hide()}
                      href={state.dialog.item.file.url}
                      target="_blank"
                      download={getRealFileNameFromURL(state.dialog.item.file.url)}
                    >Сваляне на файл</a>
                    <span onClick={() => dialogActions.hide()}>Затвори</span>
                  </div>
                </div>
              </div>
            )
          }
        </PullRefresh>
      </Wrapper>
      <Loader isLoading={state.loading}>
        <Spinner center={true} />
      </Loader>
    </>
  );
};

export default withContext(['user', 'categories'])(Page);
