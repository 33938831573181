

import React, { memo, useRef } from 'react';
import _ from 'lodash';
import { Container, InputEl } from './styles';

const Input = ({
  uid,
  label,
  type,
  rows = 0,
  className,
  value,
  placeholder,
  autoFocus,
  onChange,
  isRequired,
  isValid = true,
  validationErrorMessage,
  maxLength,
  disabled,
}) => {
  const refInput = useRef();
  const key = `${type}-${uid}`;

  const renderError = () => {
    if (isValid || validationErrorMessage === "") return null;

    return (
      <span className="error-message">
        {validationErrorMessage}
      </span>
    )
  }

  return (
    <Container key={key} className={className}>
      {label && <label htmlFor={key}>{isRequired && <span>*</span>}{label}</label>}
      <InputEl
        as={rows === 0 ? 'input' : "textarea"}
        rows={rows}
        isTextarea={rows > 0}
        id={key}
        ref={refInput}
        maxLength={maxLength}
        type={type}
        value={value || ""}
        placeholder={placeholder}
        onChange={onChange}
        autoFocus={autoFocus}
        disabled={disabled}
      />
      {renderError()}
    </Container >
  );
};


const areEqual = (prevProps, nextProps) => _.isEqual(prevProps, nextProps);
export default memo(Input, areEqual);
