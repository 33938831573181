import React from 'react';
import { Switch } from 'react-router-dom';
import styled from 'styled-components';
import ShowWhenSuperAdmin from '@utils/showWhenSuperAdmin';
import WebSockets from '@components/WebSockets';
import pages from '@pages';
import { UserMenu } from '@components/Sidebar';

import {
  SideBarContainer,
  Header,
  MenuContainer,
} from '@components/Sidebar';


const Component = () => {
  const { routes, sidebar } = pages;

  return (
    <>
      <ShowWhenSuperAdmin>
        <SideBarContainer>
          <Header />
          <MenuContainer>
            {sidebar}
          </MenuContainer>
          <UserMenu />
        </SideBarContainer>
      </ShowWhenSuperAdmin>
      <Container>
        <Switch>{routes}</Switch>
      </Container>
      <WebSockets />
    </>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`;

export default Component;
