import styled, { css } from 'styled-components';

export const DropzoneElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;

  &:hover {
    cursor: pointer;
  }
`;

export const FileIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100px;
  height: 100px;

  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & span {
    position: absolute;
    bottom: 8px;
    left: 20px;
    right: 20px;
    font-weight: 400;
    color: white;
    font-size: 14px;
    text-overflow: ellipsis;
    text-shadow: 0 0 2px rgba(0,0,0, .05);
    white-space: nowrap;
    overflow: hidden;
  }
`;
