import React, { useRef, useEffect } from 'react';
import { withContext } from '@context';
import ShowWhenSuperAdmin from '@utils/showWhenSuperAdmin';
import { useSetState } from '@utils/hooks';
import Icon from '@components/Icon';
import { FilterHolder, Filter, Results, ScrollableContainer, Container, Title, StyledNavLink } from './styles';

const Component = ({ prefix = "/documents", categories = [], history, dispatch }) => {
  const refContainer = useRef();
  const [state, setState] = useSetState({
    categories,
    allCategories: categories,
    searchText: '',
  });

  useEffect(() => {
    scrollToActiveCategory();
  }, []);

  useEffect(() => {
    setState(state => {
      state.categories = categories;
      state.allCategories = categories;
    });
  }, [categories]);

  const scrollToActiveCategory = async () => {
    await dispatch({
      type: 'ENTRIES_SET',
      payload: {
        items: [],
        total: null,
      },
    });

    setTimeout(() => {
      const { current } = refContainer;
      const currentEl = current.getElementsByClassName('active')[0];

      if (currentEl) {
        currentEl.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 10);
  }

  let results = `${state.categories.length} от ${state.allCategories.length}`;
  if (state.categories.length === 0) {
    results = `няма резултати`
  }

  const handlerSearch = (e) => {
    const { value } = e.target;
    let c = [...state.allCategories];
    if (value !== '') {
      c = state.allCategories.filter(f => f.name.toLowerCase().includes(value.toLowerCase()));
    }

    setState(state => {
      state.categories = c;
      state.searchText = value;
    });

    scrollToActiveCategory();
  }

  const linksRender = state.categories.map(({ objectId, path, name, link, count }, i) => {
    const iconName = state.categories.length === i + 1 || state.categories.length === 1 ? 'browser-folder-icon-last' : 'browser-folder-icon';

    return (
      <StyledNavLink to={`${prefix}${path}`} key={objectId} onClick={scrollToActiveCategory}>
        <Icon name={iconName} size={[18, 26]} fill="#fff" opacity=".6" />
        <span className="name">{name}</span>
        <span className="count">{count}</span>
      </StyledNavLink>
    );
  });

  return (
    <>
      <ShowWhenSuperAdmin>
        <FilterHolder>
          <Filter
            placeholder="търси ..."
            value={state.searchText}
            onChange={handlerSearch}
            searchTextLength={state.searchText.length}
            companiesLength={state.categories.length}
          />
          <Results>{results}</Results>
        </FilterHolder>
      </ShowWhenSuperAdmin>
      <Title>
        <Icon name="browser-icon-db" size={[16]} fill="#c5cfdd" />
        Ученик
      </Title>
      <ScrollableContainer>
        <Container ref={refContainer}>
          {linksRender}
        </Container>
      </ScrollableContainer>
    </>
  );
}

export default withContext(['categories'])(Component);
