import React from 'react';
import { withModal } from '@components/Modal';
import ChangePassword from './ChangePassword'

const chPassword = ({ closeFn }) => {
  return (
    <ChangePassword closeFn={closeFn} />
  );
}


export default withModal(chPassword)({});
