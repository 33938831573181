import { isMobile } from "react-device-detect";

import styled, { keyframes, css } from 'styled-components';

export const Container = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100vw;
  height: 100vh;

  ${() => isMobile && `
    padding-top: 20px;
    padding-bottom: 20px;
  `}
`;

const sizes = {
  xs: `
    max-width: 540px;
  `,
  sm: `
    max-width: 760px;
  `,
  md: `
    max-width: 980px;
  `,
  lg: `
    max-width: 1200px;
  `,
}

export const Holder = styled.div`
  margin: 0 auto;
  position: relative;
  border-radius: 4px;
  border: 6px solid #373f49;
  transition: all 0.3s 0.2s ease-out;

  ${({ size }) => size ? sizes[size] : sizes['xs']}
`;

export const Header = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 80px;
    border-radius: 4px 4px 0 0;
    padding: 0 30px;
    border-bottom: 1px solid #ededed;
    background-color: #fff;
  `,
  Title: styled.div`
    color: #47689f;
    font-size: 26px;
    font-weight: 300;
  `,
  Description: styled.div`
    margin-top: 2px;
    color: #728ca0;
    font-size: 12px;
    font-weight: 300;
    font-style: italic;
  `,
};

export const Content = styled.div`
  background-color: #fff;
  overflow: auto;
`;

export const ContentHolder = styled.div`
  padding: 20px 30px;
  overflow: hidden;
  overflow-y: scroll;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 80px;
  padding: 0 30px;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #e7e9ed;
  background-color: #fff;

  > button {
    margin-left: 20px;
  }
`;
