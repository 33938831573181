import React from 'react';
import Logo from '@components/Logo';

import { Container } from './styles';

const Header = () => {
  return (
    <Container>
      <Logo />
    </Container>
  );
};


export default Header;
