import React, { memo } from 'react';
import _ from 'lodash';
import { Container, Title, Description } from './styles';

const EmptyState = ({ className, children, title, description }) => {
  return (
    <Container className={className}>
      {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
      {description && <Description dangerouslySetInnerHTML={{ __html: description }} />}
      {children}
    </Container>
  );
}

const areEqual = (prevProps, nextProps) => _.isEqual(prevProps, nextProps);
export default memo(EmptyState, areEqual);
