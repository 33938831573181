import React, { useEffect } from "react";
import _ from "lodash";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import KeyboardEventHandler from "react-keyboard-event-handler";
import TextareaAutosize from "react-autosize-textarea";
import API from "@api";
import { toLocalTime } from '@utils/helpers';
import ShowWhenAdmin from "@utils/showWhenAdmin";
import { useSetState } from "@utils/hooks";
import Icon from "@components/Icon";

import {
  IconContainer,
  Container,
  Logo,
  TextareaRename,
  Name,
  PaidIcon,
  PreviewFrom,
} from "./styles";

const Item = (props) => {
  const [state, setState] = useSetState({
    name: "",
    flickRename: false,
  });

  useEffect(() => {
    setState(state => {
      state.name = props.name;
    });
  }, [props]);

  const onChangeRenameHandler = (e) => {
    e.persist();
    if (e.target) {
      setState(state => {
        state.name = e.target.value;
      });
    }
  }

  const formatedName = () => {
    const { name } = state;

    if (name.length > 34) {
      return `${name.substring(0, 26)}...${name.slice(-5)}`;
    }

    return name;
  }

  const handleKeys = async (key, e) => {
    e.preventDefault();

    const { cancelRenameHandler, updateItem } = props;

    if (key === "esc") {
      setState(state => {
        state.name = props.name;
      });

      cancelRenameHandler();
    }

    if (key === "enter") {
      const {
        objectId,
      } = props;

      try {
        await setState(state => {
          state.flickRename = true;
        });

        const data = await API.entries.rename({ objectId, name: state.name });

        await setState(state => {
          state.flickRename = false;
        });

        updateItem({ objectId, objectId, name: state.name });
        cancelRenameHandler();
      } catch (e) {

      }

    }
  };

  const handleDoubleClick = async () => {
    const { path, name, type, toggleItemInfo, handleFolderOpen } = props;

    if (type === "folder") {
      handleFolderOpen(`/documents${path}/${name}`);
    } else {
      toggleItemInfo();
    }
  };

  const renderExtraInfo = () => {
    const {
      paid,
      firstPreviewFrom
    } = props;

    const render = [];

    if (paid) {
      render.push(
        <ShowWhenAdmin key="Paid">
          <PaidIcon key="PaidIcon"><Icon name="icon-check" size={[10, 10]} fill="#fff" /></PaidIcon>
        </ShowWhenAdmin>
      );
    }

    if (firstPreviewFrom) {
      render.push(
        <ShowWhenAdmin key="PreviewFrom">
          <PreviewFrom>
            <Icon name="icon-eye" size={[12, 12]} fill="#fff" />
          </PreviewFrom>
        </ShowWhenAdmin>
      );
    }

    return render;
  }

  const onShow = () => {
    const { index, highlightSelected } = props;
    highlightSelected(index)
  }

  return (
    <ContextMenuTrigger id={props.objectId}>
      <IconContainer>
        <KeyboardEventHandler
          handleFocusableElements={false}
          handleKeys={["esc", "enter"]}
          onKeyEvent={(key, e) => handleKeys(key, e)}
        >
          <Container
            id={`item-${props.index}`}
            flickRename={state.flickRename}
            onClick={() => props.highlightSelected(props.index)}
            onTouchEnd={handleDoubleClick}
            onDoubleClick={handleDoubleClick}
          >
            <Logo>
              <Icon name={`icon-${props.type}`} size={[props.type === "folder" ? 64 : 58]} />
              {/* {renderExtraInfo()} */}
              {props.extension && <span>{`.${props.extension}`}</span>}
            </Logo>
            {
              props.showRename
                ? (
                  <TextareaRename>
                    <TextareaAutosize
                      autoFocus
                      maxRows={3}
                      value={state.name}
                      onChange={onChangeRenameHandler}
                    />
                  </TextareaRename>
                )
                : <Name>{formatedName()}</Name>
            }
          </Container>
        </KeyboardEventHandler>

        {
          !props.showRename && (
            <ContextMenu id={props.objectId} onShow={onShow}>
              <MenuItem onClick={handleDoubleClick}>{props.type === "folder" ? "Отвори" : "Преглед"}</MenuItem>
              <ShowWhenAdmin>
                <MenuItem onClick={() => props.handleDelete(props.objectId)}>Изтрий</MenuItem>
              </ShowWhenAdmin>
            </ContextMenu>
          )
        }
      </IconContainer>
    </ContextMenuTrigger>
  );
}

export default Item;
