import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';
import Icon from '@components/Icon';

import { Result, NoResult, Item, Path } from './styles';

const SearchResults = ({ history, closeResult, prefix, data, term }) => {
  const handleClick = ({ type, path, name }) => {
    const goTo = type === "folder" ? `${path}/${name}` : path;
    history.push(`${prefix}${goTo}`);
    closeResult();
  };

  if (term.length >= 1 && term.length < 3) {
    return <NoResult>Моля въведете 3 или повече символа</NoResult>
  }

  if (data.length === 0) {
    return <NoResult>Няма намерени резултати</NoResult>
  }

  return data.map(entry => (
    <Result key={entry.objectId} onClick={() => handleClick(entry)}>
      <Item>
        <Icon name={`icon-${entry.type}`} size={[entry.type === "folder" ? 30 : 24]} />
        {entry.name}
      </Item>

      <Path>{entry.path}</Path>
    </Result>
  ))
}

export default withRouter(memo(SearchResults));
