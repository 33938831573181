import React from "react";
import moment from 'moment';
import Calendar from 'react-calendar/dist/entry.nostyle';
import { Label } from './styles';

const Component = ({ data, onDataChange, dataKey }) => {
  const sevenDaysAgo = moment().subtract(7, 'd').startOf('day').toDate();
  return (
    <>
      <Label><span>*</span>дата</Label>
      <Calendar
        id={dataKey}
        onChange={(m) => onDataChange(dataKey, m)}
        maxDetail="month"
        prev2Label={null}
        next2Label={null}
        name={dataKey}
        value={data}
        locale="bg-BG"
        minDate={sevenDaysAgo}
      />
    </>
  )
}

export default Component;
