import React from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import KeyboardEventHandler from 'react-keyboard-event-handler';

const rootExtras = document.getElementById('root-extras');

const withPopOver = WrappedComponent => () => {
  return (props) => {
    const { closeFn, visible } = props;
    if (!visible) return null;

    const modalComponent = (
      <Container>
        <WrappedComponent {...props} style={{ zIndex: 9100}} />
        <KeyboardEventHandler
          isExclusive
          handleFocusableElements={true}
          handleKeys={['esc']}
          onKeyEvent={(key, e) => closeFn(e)} />
      </Container>
    );

    return ReactDOM.createPortal(
      modalComponent,
      rootExtras,
    );
  }
}

const Container = styled.div`
  position: absolute;
  left: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9100;
`;


export {
  withPopOver
};
