const initialState = {
  items: [],
  total: null,
};

const setEntries = (state, payload) => {
  const { items, total } = payload;
  const obj = {
    items,
    total,
  }

  return obj;
};

const resetEntries = (state, payload) => {
  return {
    items: [],
    total: null,
  }
}

const updateEntries = (state, payload) => {
  const { items } = payload;
  return {
    items,
    total: state.total,
  }
}

const createEntries = (state, payload) => {
  const total = state.total ? state.total + 1 : 1;
  return {
    items: [...state.items, payload],
    total,
  }
}

const deleteEntries = (state, payload) => {
  const updatedItems = state.items.filter(f => f.objectId !== payload);
  return {
    items: updatedItems,
    total: updatedItems.length
  }
}

const effects = {
  ENTRIES_SET: setEntries,
  ENTRIES_RESET: resetEntries,
  ENTRIES_UPDATE: updateEntries,
  ENTRIES_CREATE: createEntries,
  ENTRIES_DELETE: deleteEntries,
};

export default {
  initialState,
  effects,
};
