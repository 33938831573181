import React from 'react';
import { Switch } from 'react-router-dom';
import styled from 'styled-components';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import WebSockets from '@components/WebSockets';
import pages from './pages';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

const Component = () => {
  const { routes } = pages;

  return (
    <>
      <BrowserView>
        <Container isBrowser={isBrowser}>
          <Switch>{routes}</Switch>
        </Container>
      </BrowserView>

      <MobileView>
        <DeviceOrientation lockOrientation={'portrait'}>
          <Orientation orientation='portrait' alwaysRender={false}>
            <Container isBrowser={isBrowser}>
              <Switch>{routes}</Switch>
            </Container>
          </Orientation>
          <Orientation orientation='landscape' alwaysRender={false}>
            <Landscape>
              Please rotate your device
            </Landscape>
          </Orientation>
        </DeviceOrientation>
      </MobileView>

      <WebSockets />
    </>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  ${({ isBrowser }) => isBrowser && `
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1f252d;

    > div {
      width: 414px;
      height: 736px;
      border-radius: 8px;
      overflow: hidden;
    }
  `}
`;

const Landscape = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  overflow: hidden;
  z-index: 9999;
`;

export default Component;
